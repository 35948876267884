@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap);
#root {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
  font-family: "Open Sans", sans-serif;
}

#root {
  /* background-color: #fffefd; */
  /* background-color: #faf9f8; */
  background-color: #ffffff;
}

footer {
  /* background-color: #f5f5f5; */
  /* background-color: #f0efef; */
  background-color: #f8f9fa;
}

/* global style settings */

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  /* color: #283333; */
  /* color: #7c797b; */
  color: #6a737c;
  transition: all 0.3s ease-in-out;
}

a:hover {
  color: #3988c9;
  /* font-weight: bolder; */
}

.text-secondary {
  color: #5e666d !important;
}

.background-secondary {
  background-color: #f8f9f9;
}

/* icon hover */

.icon-normal {
  color: #31383d;
  transition: all 0.1s ease-in-out;
}

.icon-normal:hover {
  color: #616d77;
}

.icon-primary {
  color: #3988c9;
  transition: all 0.1s ease-in-out;
}

.icon-primary:hover {
  color: #336c9b;
}

.icon-complete {
  color: #2bb669;
  transition: all 0.1s ease-in-out;
}

.icon-complete:hover {
  color: #0f8546;
}

main {
  min-height: 60vh;
}

/* font size */
.font-xxl {
  font-size: 3.4rem;
}

.gradient-text {
  background-color: #000000;
  background-image: linear-gradient(100deg, #c84faa, #4480f2);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.gradient-text-blue {
  background-color: #000000;
  background-image: linear-gradient(100deg, #5ed8d2, #323aa3);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

/* background color */

/* rgb(31, 48, 56) */
/* rgba(7, 20, 26, 0.884) */
.bg-darker {
  background-color: rgba(7, 20, 26, 0.884);
}

.bg-grey {
  background-color: #485058;
}

.bg-second {
  background-color: #f8f9fa;
}

/* color */

.primary-color {
  color: #007ec3;
}

.border-primary {
  border-color: #3788ca;
}

/* hide scrollbar but keep functionality */

/* hide scrollbar for Chrome, Safari and Opera */
.overflow-scroll::-webkit-scrollbar {
  display: none;
}

.overflow-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* offcanvas */

.offcanvas {
  background-color: #ffffff !important;
}

.offcanvas-header {
  background-color: #ffffff;
}

.offcanvas-body li {
  margin-top: 1.3rem;
}

.offcanvas-body li > a {
  padding: 0.5rem;
}

@media screen and (max-width: 530px) {
  .offcanvas {
    width: 80%;
  }
}

/* nav toggle bar */

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler:hover {
  color: #868686;
  box-shadow: 0 0 0 0.2rem;
}

/* desktop nav hover */

.navbar {
  position: relative;
  z-index: 10;
}

.hover-box {
  position: absolute;
  background-color: #ffffff;
  padding-top: 4rem;
  top: 8.7rem;
  left: 0;
  right: 0;
  height: 840px;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.3s ease-in-out;
}

.hover-menu:hover > div {
  visibility: visible;
  opacity: 1;
}

/* when link is clicked */
.hover-menu.clearHover .hover-box {
  display: none;
}

.hover-content {
  max-width: 950px;
}

/* hover menu border start */
.hover-box-title-border {
  border-left: 5px solid;
  padding-left: 5px;
}

/* desktop nav hover mini (user) */

.hover-menu-mini {
  position: relative;
}

.hover-box-mini {
  display: none;
  position: absolute;
  background-color: rgb(253, 253, 253);
  top: 25px;
  right: -55px;
  width: 160px;
}

.hover-menu-mini:hover .hover-box-mini {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 7px;
  gap: 7px;
}

/* brand logo */

.brand_logo {
  width: 4rem;
  height: auto;
}

/* pointer for whatever element */

.pointer {
  cursor: pointer;
}

.flex {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

/* carousel */

.carousel-item {
  height: 500px;
  background: no-repeat scroll center scroll;
  /* no-repeat scroll center scroll; */
  /* no-repeat center center/cover; */
  -webkit-background-size: cover;
}

.carousel-item::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.23;
}

.carousel-caption {
  bottom: 30%;
}

/* contact header */

.contact-header-container {
  height: 20rem;
  overflow: hidden;
  position: relative;
}

.contact-header-img {
  height: 100%;
  width: 100%;
  background-image: url("https://images.unsplash.com/photo-1448697138198-9aa6d0d84bf4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3150&q=80");
  background: no-repeat center center/cover;
  -webkit-background-size: cover;
  opacity: 0.8;
}

.contact-header-typography {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-58%);
          transform: translateX(-50%) translateY(-58%);
  color: rgb(253, 254, 255);
  z-index: 2;
  opacity: 1;
  text-align: center;
}

.contact-header-container::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.25;
  z-index: 1;
}

/* showcase */

.showcase-container {
  height: 23rem;
  overflow: hidden;
  position: relative;
  border-radius: 7px;
}

.showcase {
  height: 100%;
  width: 100%;
  background: no-repeat center center/cover;
  -webkit-background-size: cover;
  transition: all 0.5s;
}

.showcase-container:hover .showcase {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.typography {
  position: absolute;
  color: #ffffff;
  top: 10%;
  left: 5%;
  z-index: 2;
}

.showcase-container:hover::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.2;
  z-index: 1;
  border-radius: 7px;
}

/* react player */

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

/* main header */

.main-header-lg {
  min-height: 70vh;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  background: no-repeat center/cover;
  -webkit-background-size: cover;
}

.main-header-lg > div {
  position: absolute;
  width: 50%;
  top: 15%;
  left: 5%;
  z-index: 2;
}

.main-header-xl {
  min-height: 60vh;
  overflow: hidden;
  position: relative;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
}

.bg-img {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* home smart guide border img (absolute) */

.border-img-ab {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  background-repeat: repeat;
  background-position: center;
  background-size: auto;
}

.border-img-ab-lower {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -15px;
  background-repeat: repeat;
  background-position: center;
  background-size: auto;
  height: 1rem;
}

@media screen and (min-width: 1200px) {
  .main-header-xl {
    min-height: 70vh;
  }

  .bg-img-left {
    position: relative;
    background-repeat: no-repeat;
    background-position: left;
  }
}

/* gallery */

.pic-box {
  overflow: hidden;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  opacity: 0.8;
  margin-bottom: 1.5rem;
  transition: 0.4s;
}

.pic-box:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.pic {
  max-width: 120%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pic-upload-btn {
  /* position: relative; */
  /* width: 40px; */
  /* height: 40px; */
  border: 1px solid #efb6b2;
  /* border-radius: 50%; */
  color: #f0a19c;
  /* font-weight: bold; */
  /* font-size: 30px; */
  /* transition: 0.3s; */
  /* cursor: pointer; */
}
.pic-upload-btn input {
  display: none;
  height: 0;
  width: 0;
  opacity: 0;
}

.pic-upload-btn:hover {
  background: #efb6b2;
  color: white;
}

/* .pic-upload-btn span {
  position: absolute;
  bottom: -3px;
  left: 10px;
} */

/* gallery modal */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 100px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

/* gallery delete badge */

.delete-badge {
  position: absolute;
  right: -0.4rem;
  top: -1.3rem;
  font-size: 2.5rem;
  color: rgba(241, 58, 83, 0.938);
  z-index: 5;
}

.delete-badge:hover {
  color: rgba(238, 108, 126, 0.938);
}

/* leaflet container */

.leaflet-container {
  height: 400px;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  z-index: 0;
}

/* bread crumb */

.bread-crumb-text {
  font-size: 13px;
}

.overflow-x-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
}

/* about-us */
/* article screen */

.constraint-container-size {
  max-width: 800px;
}

/* smart guide btn */

.smartguide-btn {
  border: 0.1rem solid #ac6509;
  border-radius: 2px;
  padding: 0.4rem 1.8rem;
  background-image: linear-gradient(#f8d564, #f7ce5c);
  cursor: pointer;
}

.smartguide-btn .front {
  color: #a3610a;
}

.smartguide-btn .middle {
  color: #002c5f;
}

.smartguide-btn .back {
  color: #002c5f;
}

.articleHtml img {
  object-fit: cover !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
}

/* gradient to top (transparent) */

.vertical-gradient {
  background: linear-gradient(#fff, #d2e6f0);
  background: -ms-linear-gradient(#fff, #d2e6f0);
}

/* sudo back drop */

.sudo-back-drop::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.253), #171e22);
  background: -ms-linear-gradient(rgba(0, 0, 0, 0.253), #171e22);
  opacity: 1;
  z-index: 0;
}

